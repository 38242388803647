/**
 * Exporting all the variables depending if we are in develop or in a brand specific branch
 */

module.exports = {
  logo: 'logo.svg',
  logoalt: 'logo-white.svg',
  logoShare: 'bloqhouse.png',
  scssVariables: './src/scss/variables/brands/_broccoli.scss',
  name: 'Broccoli',
  phone: '+31 (0)854 015107',
  email: 'wouter@broccoli.eu',
  website: 'https://www.broccoli.eu',
  glossary: false,
  modules: {
    landing: false,
    identification: true, // setting this to false requires manual changes
  },
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: true,
  requireLegalBanner: true,
  selectedLanguage: 'nl',
};
