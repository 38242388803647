import { Module } from 'vuex';
import { State } from '@/store/models';
import { Checkout, PaymentMethod } from '@/store/models/checkout';

export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';

export const initCheckoutStore = (initValues?: Partial<Checkout>): Checkout => ({
  assetId: initValues?.assetId || '',
  euroAmount: initValues?.euroAmount || 0,
  eurAmount: initValues?.euroAmount || 0,
  selectedDividendsFormatYear: initValues?.selectedDividendsFormatYear || ['', 0],
  sharesAmount: initValues?.sharesAmount || 0,
  totalEmissionCosts: initValues?.totalEmissionCosts || 0,
  totalEur: initValues?.totalEur || 0,
  nextStep: initValues?.nextStep || undefined,
  questionaireQuestionAndAnswers: initValues?.questionaireQuestionAndAnswers || [],
  knowledgeQuestionAndAnswers: initValues?.knowledgeQuestionAndAnswers || [],
  knowledgeQuestionAndAnswersUnprocessed: initValues?.knowledgeQuestionAndAnswersUnprocessed || [],
  experienceQuestionAndAnswers: initValues?.experienceQuestionAndAnswers || [],
  experienceQuestionAndAnswersUnprocessed: initValues?.experienceQuestionAndAnswersUnprocessed || [],
  simulationCalcuationResult: initValues?.simulationCalcuationResult || 0,
  netAnnualIncome: 0,
  liquidAssetsValue: 0,
  financialCommitmentsValue: 0,
  incomeType: null,
  agressWithRisk: null,
  agressWithRiskWarning: null,
  paymentMethod: initValues?.paymentMethod || PaymentMethod.ideal,
});

export default <Module<Checkout, State>>{
  state: initCheckoutStore(),
  mutations: {
    [UPDATE_CHECKOUT](state: Checkout, checkoutObject: Checkout): void {
      Object.assign(state, checkoutObject);
    },
    [RESET_CHECKOUT](state: Checkout, initValue: Partial<Checkout>): void {
      Object.assign(state, initCheckoutStore(initValue));
    },
  },
  actions: {
    updateCheckoutAction({ commit }, data: Checkout): void {
      commit(UPDATE_CHECKOUT, data);
    },
    resetCheckoutAction({ commit }, initValue: Partial<Checkout>): void {
      commit(RESET_CHECKOUT, initValue);
    },
  },
  getters: {
    getCheckout(state): Checkout {
      return state;
    },
    getAssetAddress(state, getters): string {
      const asset = getters.getAssetById(state?.assetId);
      if (!asset) {
        return '';
      }

      return asset.street && asset.houseNumber && asset.postalCode && asset.city
        ? `${asset.street} ${asset.houseNumber}, ${asset.postalCode}, ${asset.city}`
        : asset.city;
    },
  },
};
