import firebase from 'firebase/app';
import { OppStatus } from './opp';

/**
 * Asset/Fund structure.
 */

 export interface AssetAddress {
  country: string;
  city: string;
  street: string;
  houseNumber: string;
}
export interface Asset {
  id?: string;
  brochure?: string[];
  city: string;
  country: string;
  createdDateTime: firebase.firestore.Timestamp;
  deleted: boolean;
  company: string;
  applicationFee: number;
  /**
   * Firestore limitation of nested arrays forces to create intermediate object
   * when `dividendsFormat.contents[0][0] === 'fixed'`, it's fixed net dividends, so contents `length === 1`.
   */
  dividendsFormat: { contents: [string, number] }[];
  emissionCost: number;
  euroMin: number;
  sharesMin?: number;
  fixedDividends?: boolean;
  floorPlanImages?: string[];
  houseNumber: string;
  images?: string[];
  investmentCase: string;
  name: string;
  postalCode: string;
  premium: boolean;
  propertyDetails: string;
  prospectus?: string[];
  published: boolean;
  returnsAfterEnd?: number;
  sharePrice: number;
  sharesAvailable: number;
  startDateTime: firebase.firestore.Timestamp;
  endDateTime: firebase.firestore.Timestamp;
  street: string;
  totalValueEuro: number;
  totalValueShares: number;
  updatedDateTime?: firebase.firestore.Timestamp;
  addresses?: AssetAddress[];
  reservation?: boolean;
  type?: string;
  effectType?: string;
  shortDescription?: string;
  videoUrl?: string;
  vimeoId?: string;
  isHidden?: boolean;
  stak?: boolean;
  stakCompanyName?: string;
  historical?: boolean;
  walletId?: string; // OPP merchant uid
  oppData?: OppStatus; // Opp merchant status
  balance?: number; // OPP merchant balance
  email: string; // OPP merchant email
  tradingEnabled?: boolean;
  tradingSharePrice?: number;
  tradingFee?: number;
  tradingPriceUpperLimit?: number;
  tradingPriceLowerLimit?: number;
  tradingDocs?: string[];
}

export interface AssetReservation {
  userName: string;
  email: string;
  telephone: string;
  assetId: string;
  assetName: string;
  amount: number;
  lang: string;
  verified: boolean;
}

export enum ValuationStatus {
  Pending = 'pending',
  Applied = 'applied',
}

export interface Valuation {
  id?: string;
  asset: firebase.firestore.DocumentReference | Asset;
  sharePrice: number;
  totalValueShares: number;
  deleted: boolean;
  status: ValuationStatus;
  applyDateTime: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
}
