import { Module } from 'vuex';
import { State } from '@/store/models';
import authModal from './authModal';
import idin from './idin';
import payment from './payment';
import investments from './investments';
import bids from './bids';
import bid from './bid';
import payments from './payments';
import assets from './assets';
import identificationChecks from './identificationChecks';
import downloads from './downloads/downloads';
import checkout from './checkout';
import loginOrigin from './loginOrigin';

export default <{ [key: string]: Module<any, State> }>{
  idin,
  payment,
  bid,
  investments,
  bids,
  payments,
  assets,
  identificationChecks,
  downloads,
  authModal,
  checkout,
  loginOrigin,
};
