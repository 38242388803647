import { Module } from 'vuex';
import BigNumber from 'bignumber.js';
import { State } from '@/store/models';
import { Investment } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';
import { formatNumber } from '@/filters/number';
import { getId } from '@/helpers/utils';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

/**
 * Function that shows two decimals if there are any
 * @param numb
 */
const showTwoDecimalsOrNone = (numb): number => numb % 1 !== 0 ? Number(formatNumber(numb, 2)) : numb;

export default <Module<Investment[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    investmentHasPaidPayments: (state, getters): Function => (id: string): boolean => !!getters.getPaymentsByInvestmentId(id).length,
    investmentsLoadMore: (state, getters): Function => (position: number): Investment[] => {
      const tempInvestments = getters.getInvestmentsNonDeletedAsset as Investment[];
      const paidInvestments = tempInvestments.filter((investment: Investment): boolean => getters.investmentHasPaidPayments(investment.id));
      const investments: InvestmentsArray<any> = paidInvestments.slice(0, position > tempInvestments.length ? tempInvestments.length : position);

      investments.totalLength = paidInvestments.length;

      return investments;
    },
    // Since we cannot do a proper 'join' to get invesments with conditioned asset, we need to do it client side
    getInvestmentsNonDeletedAsset: (state, getters): Investment[] => state.filter(
      (investment): boolean => getters.getAssetById(getId(investment.asset)),
    ),
    getInvestmentById: (state): Function =>
      (id: string): Investment | undefined =>
        state.find((investment): boolean => investment.id === id),
    getInvestmentByAsset: (state): Function =>
      (assetId: string): Investment | undefined =>
        state.find((investment): boolean => getId(investment.asset) === assetId),
    // Get the number of investments that have at least one paid payment
    getLengthPaidInvestments: (state, getters): number => state.filter(
      (investment): boolean => getters.investmentHasPaidPayments(investment.id),
    ).length,
    // Get the total Euro invested from all investments (if status of payment is 'paid')
    getTotalInvested: (state): number =>
      state.reduce((investmentA, investmentB): number =>
        investmentA + ((investmentB.boughtSharesTotal || 0) * (investmentB.asset as Asset).sharePrice), 0),
    getTotalInvestedNonNeleman: (state): number =>
      state.reduce((total, investment): number => {
        if (investment.asset.id === '46DHv6zxeUoqWvoUgtJV' || investment.asset.id === 'd0PNrBQ4vWscnysgXvsH' || investment.asset.id === 'Rs57vRAKv4a3iSYCGVBp') {
          return total;
        }
        return total + ((investment.boughtSharesTotal || 0) * (investment.asset as Asset).sharePrice);
      }, 0),
    getSharesTotalInvested: (state): number =>
      state.reduce((investmentA, investmentB): number => investmentA + (!(investmentB.asset as Asset).premium ? (investmentB.boughtSharesTotal || 0) : 0), 0),
    getReturnProfit: (state, getters): Function => (investment: Investment): number => {
      let amountNow = 0;
      let amountPaid = 0;
      const asset = investment.asset as Asset;
      if (asset.historical) {
        const historicalTotals = getters.getHistoricalTotals(investment.id) as { shares: number, amount: number };
        amountNow = historicalTotals.shares * asset.sharePrice;
        amountPaid = historicalTotals.amount;
      } else {
        amountNow = (investment.boughtSharesTotal || 0) * asset.sharePrice;
        amountPaid = investment.paidEuroTotal || 0;
      }
      return amountPaid
        ? new BigNumber(amountNow)
          .dividedBy(amountPaid)
          .minus(1)
          .multipliedBy(100)
          .toNumber()
        : 0;
    },
    getTotalReturn: (state, getters): number => {
      const totals = state.reduce((acum, investment): { amountPaid: number, amountNow: number } => {
        const asset = investment.asset as Asset;
        if (!asset.premium) {
          if (asset.historical) {
            const historicalTotal = getters.getHistoricalTotals(investment.id) as { shares: number, amount: number };
            acum.amountPaid = new BigNumber(acum.amountPaid).plus(historicalTotal.amount).toNumber();
            acum.amountNow = new BigNumber(acum.amountNow).plus(new BigNumber(historicalTotal.shares).multipliedBy(asset.sharePrice)).toNumber();
          } else {
            acum.amountPaid = new BigNumber(acum.amountPaid).plus(investment.paidEuroTotal || 0).toNumber();
            acum.amountNow = new BigNumber(acum.amountNow).plus(new BigNumber(investment.boughtSharesTotal || 0).multipliedBy(asset.sharePrice)).toNumber();
          }
        }
        return acum;
      }, { amountPaid: 0, amountNow: 0 });
      return totals.amountPaid ? new BigNumber(totals.amountNow)
        .dividedBy(totals.amountPaid)
        .minus(1)
        .multipliedBy(100)
        .toNumber()
        : 0;
    },
  },
};
