import { Module } from 'vuex';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { Payment } from '@/store/models/investment';
import BigNumber from 'bignumber.js';
import { getId } from '@/helpers/utils';

export interface AssetsArray<T> extends Array<T> {
  totalLength?: number;
}

export default <Module<Asset[], State>> {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    assetsLoadMore: (state): Function => (position: number, filteredAssets?: Asset[]): Asset[] => {
      const stateAssets = filteredAssets || state;
      const assets: AssetsArray<any> = stateAssets.slice(
        0, position > stateAssets.length ? stateAssets.length : position,
      );

      assets.totalLength = stateAssets.length;

      return assets;
    },
    getAssetById: (state): Function =>
      (id: string): Asset | undefined =>
        state.find((asset): boolean => asset.id === id),
    // Get all the assets that the user has invested in (ToDo: improve 'any' typing)
    getInvestedAssets: (state, getters): Asset[] => {
      const assets: { [key: string]: Asset } = {};
      // Not using a map because it could return an array of undefined
      getters.getPaidPayments.forEach((payment: Payment): any => {
        // Could be that the asset is not retrieved yet && check if we have already inserted it
        if (getId(payment.asset) && !assets[getId(payment.asset)]) {
          assets[getId(payment.asset)] = getters.getAssetById(getId(payment.asset)) as Asset;
        }
      });
      return Object.values(assets);
    },
    getNotInvestedAssets: (state, getters): Asset[] =>
      state.filter((asset): boolean =>
        !getters.getInvestedAssets.map((asset: Asset): string => asset.id!).includes(asset.id)),
    getAssetByInvestmentId: (state, getters, rootState): Function =>
      (investmentId: string): Asset | undefined =>
        state.find((asset): boolean => {
          const investment = rootState.investments.find(
            (investment): boolean => investment.id === investmentId,
          );

          return !!investment && (investment.asset.id === asset.id);
        }),
    // Avoiding mutations
    getAssetsOrderedByCreatedDatetimeAsc: (state): Asset[] => [...state].sort((a, b): number => a.createdDateTime.seconds - b.createdDateTime.seconds),
    getAssetTotalValue: (state, getters, rootState): Function =>
      (assetId: string): number => {
        const asset = state.find((asset): boolean => asset.id === assetId);
        const valuation = rootState.valuations.find((valuation): boolean => valuation.id === 'initial' && valuation.asset.id === assetId);
        return new BigNumber(valuation?.sharePrice || asset?.sharePrice || 0)
          .multipliedBy(valuation?.totalValueShares || asset?.totalValueShares || 0)
          .toNumber();
      },
    getTradingAssets: (state): Asset[] => state.filter((asset): boolean => asset.tradingEnabled ?? false),
  },
};
