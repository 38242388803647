import { Module } from 'vuex';
import { State } from '@/store/models';
import { DefaultBid as Bid } from '@/store/models/bid';
import { getId } from '@/helpers/utils';

export default <Module<Bid[], State>>{
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getBidsByInvestmentId: (state): Function =>
      (investmentId: string): Bid[] | undefined => state.filter((bid): boolean => getId(bid.investment) === investmentId),
    getBidById: (state): Function =>
      (id: string): Bid | undefined => state.find((bid): boolean => bid.id === id),
  },
};
