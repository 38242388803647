import VueI18n from 'vue-i18n';
import to from 'await-to-js';
import Vue from 'vue';
import { auth } from '@/firebase';

const { selectedLanguage } = require('../whitelabel.config');

Vue.use(VueI18n);

export type Language = 'nl' | 'en';
export const languages: Language[] = ['nl', 'en'];

export const defaultLanguage: Language = selectedLanguage;
export const localStorageKey: string = 'vue-i18n-language';

export const isValidLang = (lang: string | undefined): lang is Language => !!lang && languages.includes(lang as any);

export const i18n = new VueI18n({
  locale: defaultLanguage, // set locale
  fallbackLocale: 'en',
  messages: {}, // set locale messages
});

const isAppleWebkit = navigator.userAgent.indexOf('AppleWebKit') !== -1;

// Setting default language
import(/* webpackChunkName: "lang-[request]" */ `@/lang/${defaultLanguage}.ts`)
  .then((langFile): void => {
    i18n.setLocaleMessage(defaultLanguage, isAppleWebkit ? Object.freeze(langFile.default[defaultLanguage]) : langFile.default[defaultLanguage]);
  })
  .catch((e): void => {
    throw new Error(e);
  });

/**
 * This function lazy loads the language file and sets the language in i18n, firebase auth and localStorage
 * @param lang Language
 */
export async function loadLanguageAsync(lang: Language): Promise<void> {
  if (i18n.locale !== lang) {
    if (!i18n.messages[lang]) {
      const [langFileError, langFile] = await to(import(/* webpackChunkName: "lang-[request]" */ `@/lang/${lang}.ts`));
      if (langFileError) {
        throw langFileError;
      }
      i18n.setLocaleMessage(lang, langFile.default[lang]);
    }
    i18n.locale = lang;
  }
  auth.languageCode = lang;
  localStorage.setItem(localStorageKey, lang);
}
