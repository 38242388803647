export enum CheckoutStepNames {
  Identification = 'identification', // normal identification component
  Questionnaire = 'questionnaire ', // Questionnaire
  RiskQuestionnaire = 'riskQuestionnaire',
  Investment = 'amount', // CheckoutInvestment
  Terms = 'terms', // CheckoutLegal
  PaymentMethods = 'payment-methods', // CheckoutPaymentMethods
  Payment = 'payment', // no component
  Status = 'status', // CheckoutStatus
}

export const enum PaymentMethod {
  wallet = 'wallet',
  ideal = 'ideal',
  bcmc = 'bancontact',
  sepa = 'sepa',
  'paypal-ppcp' = 'paypal',
  'creditcard' = 'creditcard',
  'sofort-banking' = 'sofort',
  mybank = 'mybank',
  giropay = 'giropay',
  mandate = 'mandate',
  multi = 'multi',
  'pi-single' = 'pi-single',
}

/**
 * Transform OPP's shorthands to actual payment method names.
 */
export const paymentMethodToText: { [K in keyof typeof PaymentMethod as Lowercase<K>]: string } = {
  wallet: 'wallet',
  ideal: 'iDEAL',
  bcmc: 'Bancontact',
  sepa: 'SEPA',
  'paypal-ppcp': 'Paypal',
  creditcard: 'Creditcard',
  'sofort-banking': 'SOFORT',
  mybank: 'MyBank',
  giropay: 'Giropay',
  mandate: 'Mandate',
  multi: 'Multi',
  'pi-single': 'PIS',
};

export enum IdentificationCheckoutStepNames {
  Identification = 'identification', // normal identification component
  Questionnaire = 'questionnaire ', // Questionnaire
  RiskQuestionnaire = 'riskQuestionnaire',
}

export type Checkout = {
  assetId: string,
  sharesAmount: number,
  eurAmount: number,
  euroAmount: number,
  totalEur: number,
  totalEmissionCosts: number,
  selectedDividendsFormatYear: [string, number],
  nextStep?: CheckoutStepNames | IdentificationCheckoutStepNames | 'lastStep',
  questionaireQuestionAndAnswers: { question: string, answer: string, type: string }[],
  knowledgeQuestionAndAnswers: { question: string, answer: string, type: string }[],
  knowledgeQuestionAndAnswersUnprocessed: { question: string, answer: string, type: string }[],
  experienceQuestionAndAnswers: { question: string, answer: string, type: string }[],
  experienceQuestionAndAnswersUnprocessed: { question: string, answer: string, type: string }[],
  simulationCalcuationResult: number,
  netAnnualIncome: number,
  liquidAssetsValue: number,
  financialCommitmentsValue: number,
  incomeType: null | boolean,
  agressWithRisk: null | boolean,
  agressWithRiskWarning: null | boolean,
  paymentMethod: PaymentMethod,
};

export interface CheckoutCloudFunctionParameters {
  assetId: string,
  redirectUrl: string,
  sharesAmount: number,
  lang: string,
  selectedDividendsFormatYear: [string, number],
  brand?: string
  type: undefined | 'loan', // this is not a parameter in the CF but we still send it for now
}

export type Step = {
  name: CheckoutStepNames | IdentificationCheckoutStepNames,
  /**
   * what is `matched` in the router
   */
  route: string,
  /**
   * the pointer to the correct v18n property
   */
  display: string,
};
