import { db } from '@/firebase';
import { Asset } from '@/store/models/asset';
import { IdentificationCheckoutStepNames } from '@/store/models/checkout';
import { Investment } from '@/store/models/investment';
import { Investor, User } from '@/store/models/user';
import to from 'await-to-js';
import firebase from 'firebase/app';
import { IdentCheckoutRouteNames } from './checkout';

/**
 * getId (par): string
 * Due to permissions errors we need to limit the vuexfire autoresolve feature. That creates an scenario in which object references
 * in a db object can be now an string, firestore reference or the object itself. This method retrieves the id in all the cases
 * @param par relationship field
 * @returns model id
 */
export function getId(par: string | firebase.firestore.DocumentReference | Asset | Investment | Investor): string {
  if (typeof par === 'string') {
    return par.split('/').pop()!;
  }
  return par?.id || '';
}

export async function checkAndUpdateRouteIfNecessary(user: any, isInvestor: boolean, router: any, route: any): Promise<void> {
  const [getIDRError, getIDRSuccess] = await to(db
    .collection('identificationRequests')
    .doc(user?.id)
    .get());

  if (user && user?.tier === 'account') {
    // identification
    if (!isInvestor && (getIDRError || !getIDRSuccess?.exists) && !user.questionnaireAnswered) {
      router.push({ name: IdentCheckoutRouteNames.MAINWITHOUTINVESTMENT, params: { lang: route.params.lang } });
      return;
    }
    // questionnaire
    if (getIDRSuccess && !isInvestor && !user.questionnaireAnswered) {
      router.push({ name: `IdentificationCheckout${IdentificationCheckoutStepNames.Questionnaire}`, params: { lang: route.params.lang } });
      return;
    }
    // simulation
    if (user.questionnaireAnswered && !user?.simulationAnswered) {
      router.push({ name: `IdentificationCheckout${IdentificationCheckoutStepNames.RiskQuestionnaire}`, params: { lang: route.params.lang } });
      return;
    }
  }
}
