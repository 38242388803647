import firebase from 'firebase/app';
import { PaymentMethod } from '@/store/models/checkout';
import { Investor } from './user';
import { Asset } from './asset';

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments.
 */
export interface Investment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  asset: firebase.firestore.DocumentReference | Asset;
  paidEuroTotal?: number;
  boughtSharesTotal?: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
}

/**
 * Defining all the statuses a Payment can have.
 */
export enum PaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
  Refunded = 'refunded',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  OPP = 'OPP',
  Mollie = 'Mollie',
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
}

export const enum ExtendPaymentMethod {
  wallet = 'wallet',
}

export interface PaymentRelation {
  ref: firebase.firestore.DocumentReference,
  sharesAmount: number;
}

/**
 * Sell or buy operation
 */
 export const enum PaymentType {
  Sell = 'sell',
  Buy = 'buy',
}

/**
 * Actual payment info is placed here.
 */
export interface DefaultPayment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  bid?: firebase.firestore.DocumentReference; // If the payment has been created as a result of a bid exchange
  type: PaymentType;
  dividendsFormat: [string, number];
  provider: PaymentProvider;
  providerData: {
    method?: PaymentMethod | ExtendPaymentMethod,
    id: string,
    amount: {
      currency: string,
      value: string,
    },
    status: PaymentStatus,
    metadata: {
      uid?: string,
      euroAmount: number,
      sharesAmount: number,
      investmentId?: string,
      assetId?: string,
      paymentId?: string,
      selectedDividendsFormatYear?: [string, number];
    },
    [key: string]: any,
  };
  deleted: boolean;
  ended?: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  historical?: boolean;
}

export interface BuyingPayment extends DefaultPayment {
  type: PaymentType.Buy;
  from?: firebase.firestore.DocumentReference;
  usedShares: PaymentRelation[];
}

export interface SellingPayment extends DefaultPayment {
  type: PaymentType.Sell;
  to?: firebase.firestore.DocumentReference;
  usedPayments: PaymentRelation[];
}

export type Payment = BuyingPayment | SellingPayment
