import { GetterTree } from 'vuex';
import { UserTier, MerchantStatus, ComplianceStatus } from '@/store/models/user';
import { State } from './models';

export default <GetterTree<State, State>> {
  isUserLoggedIn: (state): boolean => state.auth?.status === 'success' && state.auth.payload?.user?.uid,

  /**
   * Typeguard getter to check if the user is an investor.
   */
  isInvestor: ({ user }): boolean => user?.tier === UserTier.Investor,

  /**
   * Typeguard getter to check if the user is an investor.
   */
  isUser: ({ user }): boolean => user?.tier === UserTier.Account,

  hasInvalidMerchantOrCompliance: ({ user }): boolean =>
    !!(user?.oppData && (user?.oppData.merchantStatus !== MerchantStatus.live || user?.oppData.complianceStatus !== ComplianceStatus.verified)),

  hasWalletId: ({ user }, getter): boolean => !getter.isOppEnabled ? true : !!user?.walletId,

  walletIsSetUp: ({ user }, getter): boolean => !getter.isOppEnabled ? true : !!(user?.walletId && !getter.hasInvalidMerchantOrCompliance),

  walletOnReview: ({ user }, getter): boolean => !getter.isOppEnabled ? false : !!((user?.walletId && getter.hasInvalidMerchantOrCompliance)),
};
